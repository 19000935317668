@import "variables.scss";
@import "responsive.scss";
@import "mixins.scss";

body {
  margin: 0;
  font-family: "Noto Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $black-secondary;
}

code {
  font-family: "Noto Sans", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

// section
section {
  @include desktop {
    padding: 0 10%;
    padding-top: 11em;
  }
  @include until($widescreen) {
    padding-top: 10em;
  }
  @include until($tablet) {
    padding-top: 12em;
  }
  padding: 25px 3%;
  padding-top: 11em;
  &.component-section {
    padding: 0 10%;
    @include mobile {
      padding: 0 3%;
    }
  }
  &.top-content-section {
    padding: 0 10%;
    @include mobile {
      padding: 25px 3%;
    }
    .heading {
      margin: 10%;
    }
  }
}

.sort-icon {
  height: 23px;
  width: 30px;
}

// color classes
.primary-color {
  color: $theme-color;
  text-decoration: none;
}
a {
  text-decoration: none;
}
.heading {
  font-weight: 600;
  font-size: 21px;
  margin-top: 20px;
  margin-bottom: 30px;
  // color: $black-secondary;
  color: $theme-color;
  @include mobile {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
.sub-heading {
  color: $theme-color;
  font-weight: 600;
  @include mobile {
    margin-left: 0;
  }
}

.text-center {
  text-align: center;
}

// paragraph
.paragraph-txt {
  text-align: justify;
  font-size: 14px;
  line-height: 30px;
  margin-top: 2em;
}

// buttons
button {
  height: 3em;
  background: $theme-color;
  border-radius: 33px;
  border: none;
  outline: 0;
  padding: 0 30px;
  margin: 20px 0;
  cursor: pointer;
}
.button-inline {
  width: fit-content;
}
.button-block {
  button {
    width: 100%;
  }
}
.button-txt {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: $white;
  padding: 0 15px;
}

.empty-msg {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  height: 300px;
  text-align: center;
}

.space-large {
  height: 100px;
}
.space-small {
  height: 80px;
}
.space-xs {
  height: 60px;
}

@include mobile {
  .space-large {
    height: 60px;
  }
  .space-small {
    height: 40px;
  }
  .space-xs {
    height: 10px;
  }
}

// dropdown
.dropdown {
  position: relative;
  @include until($tablet) {
    width: -webkit-fill-available;
    margin-left: 0;
    margin-top: 10px;
  }
  .dropdown-input {
    background-image: url(../assets/icons/dropdown.svg);
    background-position: calc(100% - 5px) 53%;
    background-repeat: no-repeat;
    font-family: "Noto Sans";
    width: 100%;
    padding: 5px 15px 5px 5px;
    box-sizing: border-box;
    border: 1px solid $white-secondary;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    outline: 0;
  }
  .filter-input {
    background-image: url(../assets/icons/sort-descending.svg);
    background-size: 15px 50%;
    padding-right: 20px;
  }
  .dropdown-content {
    border-bottom: 1px solid $white-secondary;
    border-left: 1px solid $white-secondary;
    border-right: 1px solid $white-secondary;
  }
  .dropdown-content {
    position: absolute;
    background-color: $white;
    width: -webkit-fill-available;
    max-height: 5.8em;
    border-radius: 0 0 6px 6px;
    overflow-y: scroll;
    z-index: 1;
    margin-top: 0;
    .dropdown-item {
      color: $black;
      font-size: 12px;
      padding: 7px;
      text-decoration: none;
      display: block;
      cursor: pointer;
      &:hover {
        background-color: $white-secondary;
      }
    }
  }
}

.no-content {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.filter-sort {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  @include mobile {
    flex-direction: column;
    margin-bottom: 20px;
    .dropdown-input {
      width: 100%;
    }
  }
  @include desktop {
    .dropdown {
      margin-left: 10px;
    }
  }
}

.input-error {
  font-size: $error-font;
  color: $red;
}

.full-width {
  width: 100%;
}

// checkbox
.checkbox-container {
  display: flex;
  color: $grey-primary;
}
input[type="checkbox"] {
  cursor: pointer;
}
.checkbox-text {
  display: flex;
  @include until($mobile-m) {
    font-size: 11px;
    line-height: 18px;
  }
  @include mobile {
    display: block;
  }
}

//search
input {
  &.search-input {
    background: $white;
    border: 1px solid $grey-primary;
    box-sizing: border-box;
    border-radius: 33px;
    padding: 7px 30px 7px 10px;
    color: $black-secondary;
    height: 29px;
    background-image: url("../assets/icons/search.svg");
    background-repeat: no-repeat;
    background-position: calc(100% - 12px) center;
    background-size: 13px 13px;
    margin: 0 0 5px 0;
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    outline: 0;

    @include until($desktop) {
      margin: -4px 0 0 0;
      height: 26px;
    }
  }
}
.search-block {
  width: 275px;
  float: right;

  @include until($widescreen) {
    width: 100%;
  }
  @include until($desktop) {
    width: 195px;
  }
}
.search-button {
  height: 29px;
  width: 100px;
  background: transparent;
  border-radius: 33px;
  outline: 0;
  padding: 0;
  margin: 0 0 10px 0;
  box-sizing: border-box;
  font-family: "Noto Sans";
  float: right;
}
.search-button {
  border: 1px solid $theme-color;
}
.search-button-txt {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 27px;
  text-align: center;
  color: $theme-color;
  padding: 0 10px 0 0;
}
.search-icon {
  vertical-align: middle;
  @include until($widescreen) {
    width: 15px;
  }
  @include until($desktop) {
    width: auto;
  }
}
.search-icon-highlight {
  height: 13px;
  @include until($desktop) {
    height: 18px;
  }
}
.search-input-wrapper {
  display: flex;
  flex-direction: column-reverse;
  width: 275px;
  @include until($widescreen) {
    width: 100%;
  }
}
.search-result-content {
  margin-top: -22px;
  background: $white;
  border-radius: 0 0 17px 17px;
  padding-top: 13px;
  @include until($desktop) {
    margin-top: -13px;
  }
}
.dividing-line {
  margin-top: 0.2em;
  border-top: 1px solid $grey-primary;
  @include until($desktop) {
    margin-top: -0.05em;
  }
}
.search-results-list {
  max-height: 22.35em;
  overflow-y: scroll;
  z-index: 1;
  width: 273px;
  background: $white;
  border-radius: 0 0 18px 18px;
  position: absolute;
  @include until($widescreen) {
    width: 165px;
  }

  @include until($desktop) {
    width: 193px;
  }
}
.search-results-list {
  border-bottom: 1px solid $grey-primary;
  border-left: 1px solid $grey-primary;
  border-right: 1px solid $grey-primary;
}
.search-result-item {
  padding: 0.4em 2em 0.4em 1em;
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: $black-secondary;
  text-align: left;
  cursor: pointer;
}

.search-title {
  color: $theme-color;
  font-size: 15px;
  font-weight: 600;
  text-align: left;
  padding: 0.4em 1em 0.4em 0.8em;
  margin-bottom: 0.1em;
  margin-top: 0.23em;
  background: $white-secondary;
}
.see-more {
  color: $see-more-search;
  font-size: 12px;
  margin-bottom: 0.1em;
  margin-top: 0.23em;
  text-align: left;
  padding: 0.4em 0 0.4em 1em;
  cursor: pointer;
}
.search-result-content + .search-input {
  border-radius: 17px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.no-results {
  padding: 0.4em 2em 0.4em 1em;
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: $black-secondary;
  border-bottom: 1px solid $grey-primary;
  border-left: 1px solid $grey-primary;
  border-right: 1px solid $grey-primary;
  border-bottom-right-radius: 17px;
  border-bottom-left-radius: 17px;
  position: absolute;
  background: $white;
  text-align: left;
  z-index: 1;
}
.no-results {
  width: 237px;
  @include until($widescreen) {
    width: 129px;
  }

  @include until($desktop) {
    width: 157px;
  }
}
.search-mobile-button {
  background: none;
  height: auto;
  padding: 0;
  margin: 0;
}

.pointer {
  cursor: pointer;
}

.slick-slider {
  display: flex !important;
}

.slick-list {
  width: 100%;
}

.hide-widescreen {
  @include until($widescreen) {
    display: none;
  }
}

.logo-img {
  width: 400px;
  height: 100px;
}

// external styles
.external-heading {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  color: $black-secondary;
  margin-bottom: 20px;
}
.external-left {
  padding: 30px;
  @include mobile {
    padding: 20px 15px;
  }
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  .field {
    input {
      width: 100%;
    }
  }
  .checkbox-container {
    margin-top: 10px;
    justify-content: space-between;
    @include until($desktop) {
      justify-content: left;
    }
  }
  .text-margin {
    margin: 0 3px;
    color: $theme-color;
  }
  .account {
    display: flex;
    justify-content: center;
    color: $grey-primary;
    &.forgot-password-link {
      margin-top: 20px;
    }
    .primary-color {
      margin-left: 4px;
      text-decoration: none;
    }
  }
}

//privacy
#pdfobject {
  height: 100vh;
}
